import React from "react";
import { Link, useLocation } from "react-router-dom";
import { WarningCircleIcon } from "../components/svg/icons/WarningCircle";
import {
	DEFAULT_ARTIST_PLAN_NAME,
	DEFAULT_CURATOR_PLAN_NAME,
} from "../const/plans";
import { useCurrentUserContext } from "../contexts/CurrentUser";
import { useArtistById } from "../hooks/useArtistById";
import useBreakpoint from "../hooks/useBreakpoints";
import { midClasses, useMainNav } from "../hooks/useMainNav";
import Footer from "../views/Footer";
import Header from "../views/Header";

const UpgradeNowBadge = () => {
	const { userId } = useCurrentUserContext();
	const { user } = useCurrentUserContext();
	const userIsFree = (u) => {
		if (!u || !u.subscriptionPlanId) {
			return false;
		}
		if (
			u.subscriptionPlanId === DEFAULT_CURATOR_PLAN_NAME ||
			u.subscriptionPlanId === DEFAULT_ARTIST_PLAN_NAME
		) {
			return true;
		}
		return false;
	};

	return (
		<>
			{userIsFree(user) ? (
				<Link
					to={
						userId
							? { pathname: "/profile/user", state: { openChangePlan: true } }
							: "/pricing"
					}
					className="w-100 cursor-pointer py-s6 bg-color-purpleLight d-flex align-items-center justify-content-center"
				>
					<span className="tbodysm text-color-neutral900">
						Upload & Sell more Artworks.
					</span>
					&nbsp;
					<span className="tbodysm fw-semibold text-uppercase text-color-neutral900">
						Upgrade Now
					</span>
				</Link>
			) : (
				<></>
			)}
		</>
	);
};

const ConstructionWorkBadge = () => {
	const breakpoint = useBreakpoint();

	const isSmall = breakpoint === "xs";

	return (
		<div className="w-100 cursor-pointer py-s6 bg-color-warningDark d-flex flex-column flex-sm-row align-items-center justify-content-center">
			<span className="tbodysm fw-semibold text-uppercase text-color-neutral900">
				<span style={{ position: "relative", bottom: "1px" }}>
					<WarningCircleIcon strokeWidth={3} width={16} height={16} />
				</span>
				<span>ANASAEA is being updated.</span>
			</span>
			{!isSmall && <>&nbsp;</>}
			<span className="tbodysm text-color-neutral900">
				Some features may not work as expected.
			</span>
		</div>
	);
};

export const MainLayout: React.FC = ({ children }) => {
	const location = useLocation();
	const { user } = useCurrentUserContext();
	const { artist, isLoading } = useArtistById(user?.artistId ?? "");
	return (
		<>
			<UpgradeNowBadge />
			{/* <ConstructionWorkBadge /> */}

			{!isLoading && (
				<Header user={user} artist={artist} classes={midClasses} isLightBg />
			)}

			{children}

			{!location.pathname.includes("editExhibition") &&
				!location.pathname.includes("profile") && <Footer />}
		</>
	);
};

export const HomePageLayout: React.FC = ({ children }) => {
	const { user } = useCurrentUserContext();
	const { artist, isLoading } = useArtistById(user?.artistId ?? "");
	const { classes, isLightBg } = useMainNav();

	return (
		<>
			<UpgradeNowBadge />
			{/* <ConstructionWorkBadge /> */}
			{!isLoading && (
				<Header
					user={user}
					artist={artist}
					classes={classes}
					isLightBg={isLightBg}
				/>
			)}

			{children}

			<Footer />
		</>
	);
};

export const FullPageLayout = ({ children }) => {
	const location = useLocation();
	return (
		<>
			{children}
			{!location.pathname.includes("signin") &&
				!location.pathname.includes("signup") && <Footer />}
		</>
	);
};

export const FullPageLayoutNoFooter = ({ children }) => {
	const location = useLocation();
	return <>{children}</>;
};

export const EmptyLayout = ({ children }) => {
	return <>{children}</>;
};
